
import {
  Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import LogPane from '@/components/editor/LogPane.vue';

@Component({
  components: {
    LogPane,
  },
})
export default class Logging extends Vue {}
